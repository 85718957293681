import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/yogaday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/yogaday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/yogaday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/yogaday/4.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const YogaDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/school-programs/yogaday/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/yogaday/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/yogaday/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/yogaday/4.webp`;
    
    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        // {
        //     src: p5,
        //     source: p5,
        //     rows: 1,
        //     cols: 1,
        // },
        // {
        //     src: p6,
        //     source: p6,
        //     rows: 1,
        //     cols: 1,
        // }, 
       
       
//       {
//           src: p6,
//           source: p6,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p7,
//           source: p7,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p8,
//           source: p8,
//           rows: 1,
//           cols: 1,
//       }, 
//       {
//           src: p9,
//           source: p9,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p10,
//           source: p10,
//           rows: 1,
//           cols: 1,
//       }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                INTERNATIONAL DAY OF YOGA : 2024-25 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Class: Class 10    Date: 21 June 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur enthusiastically celebrated the International Day of Yoga on Friday, 21 June 2024, aiming to imbue students with a profound understanding of yoga and its myriad benefits. The theme chosen for this year was "Yoga for Self and Society," which emphasizes on Yoga’s dual role in the wellbeing of self and the community. The dedicated students of Class 10 wholeheartedly engaged in the event, skilfully demonstrating various Asanas that embody our shared vision of individual and societal well-being.  
                                    <br></br>
                                    In addition, the teachers actively participated in a special yoga session tailored for their Physical strength, Mental resilience and Emotional balance, enhancing their overall wellbeing. During this session, they comfortably sat on chairs/ yoga mats and practised rejuvenating Asanas that promote relaxation of both the mind and body. By the end of the session, the teachers expressed a profound sense of tranquillity and rejuvenation.
                                    <br></br>
                                    Overall, the commemoration of the International Day of Yoga at National Public School Yeshwantpur exemplified the profound commitment to fostering physical and mental well-being among students and staff alike.
                                   
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ Yoga is the journey of the Self, through the Self, to the Self – International Day of Yoga : 2024 ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default YogaDay2024;